export type SpaceRole = {
  id: string
  name: string
  color: number
  position: number
  permissions: string
  flags: number
}

export class SpaceRoleRecord {
  id: string
  spaceId: string
  name: string
  color: number
  position: number
  permissions: bigint
  flags: number

  constructor(spaceId: string, spaceRole: SpaceRole) {
    this.id = spaceRole.id
    this.spaceId = spaceId
    this.name = spaceRole.name
    this.color = spaceRole.color
    this.position = spaceRole.position
    this.permissions = BigInt(spaceRole.permissions)
    this.flags = spaceRole.flags
  }

  update(spaceRole: SpaceRole): void {
    this.name = spaceRole.name
    this.color = spaceRole.color
    this.position = spaceRole.position
    this.permissions = BigInt(spaceRole.permissions)
    this.flags = spaceRole.flags
  }

  get isEveryone() {
    return this.id === this.spaceId
  }

  toJSON(): SpaceRole {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      position: this.position,
      permissions: this.permissions.toString(),
      flags: this.flags,
    }
  }
}
