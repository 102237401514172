import type {ChannelOverwriteType, ChannelType} from "~/Constants"
import type {SpaceRecord} from "~/records/SpaceRecord"
import SpaceStore from "~/stores/SpaceStore"
import * as SnowflakeUtils from "~/utils/SnowflakeUtils"

export type ChannelOverwrite = {
  id: string
  type: ChannelOverwriteType
  allow: string
  deny: string
}

export class ChannelOverwriteRecord {
  id: string
  type: ChannelOverwriteType
  allow: bigint
  deny: bigint

  constructor(overwrite: ChannelOverwrite) {
    this.id = overwrite.id
    this.type = overwrite.type
    this.allow = BigInt(overwrite.allow)
    this.deny = BigInt(overwrite.deny)
  }
}

export type Channel = {
  id: string
  space_id: string
  name: string
  topic: string | null
  url: string | null
  type: ChannelType
  position: number
  flags: number
  last_message_id: string | null
  last_pin_at: number | null
  overwrites?: Array<ChannelOverwrite>
}

export class ChannelRecord {
  id: string
  spaceId: string
  name: string
  topic: string | null
  url: string | null
  type: ChannelType
  position: number
  flags: number
  lastMessageId: string | null
  lastPinAt: number | null
  overwrites: Record<string, ChannelOverwriteRecord>

  constructor(channel: Channel) {
    this.id = channel.id
    this.spaceId = channel.space_id
    this.name = channel.name
    this.topic = channel.topic
    this.url = channel.url
    this.type = channel.type
    this.position = channel.position
    this.flags = channel.flags
    this.lastMessageId = channel.last_message_id
    this.lastPinAt = channel.last_pin_at
    this.overwrites = {}

    if (channel.overwrites) {
      for (const overwrite of channel.overwrites) {
        this.overwrites[overwrite.id] = new ChannelOverwriteRecord(overwrite)
      }
    }
  }

  getSpace(): SpaceRecord {
    return SpaceStore.getSpace(this.spaceId)!
  }

  toJSON(): Channel {
    return {
      id: this.id,
      space_id: this.spaceId,
      name: this.name,
      topic: this.topic,
      url: this.url,
      type: this.type,
      position: this.position,
      flags: this.flags,
      last_message_id: this.lastMessageId,
      last_pin_at: this.lastPinAt,
      overwrites: Object.values(this.overwrites).map((overwrite) => ({
        id: overwrite.id,
        type: overwrite.type,
        allow: overwrite.allow.toString(),
        deny: overwrite.deny.toString(),
      })),
    }
  }

  get createdAt(): number {
    return SnowflakeUtils.extractTimestamp(this.id)
  }
}
