import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"

type State = {
  lastSelectedSpaceId: string | null
  selectedSpaceId: string | null
}

const initialState: State = {
  lastSelectedSpaceId: null,
  selectedSpaceId: null,
}

class SelectedSpaceStore extends PersistedStore<State> {
  constructor() {
    super(initialState, "SelectedSpaceStore", 1)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "SPACE_SELECT":
        return this.handleSpaceSelect(action)
      case "SPACE_DESELECT":
        return this.handleSpaceDeselect()
      default:
        return false
    }
  }

  private handleSpaceSelect({spaceId}: {spaceId: string}) {
    this.setState((prevState) => ({
      lastSelectedSpaceId: prevState.selectedSpaceId,
      selectedSpaceId: spaceId,
    }))
  }

  private handleSpaceDeselect() {
    this.setState((prevState) => ({
      lastSelectedSpaceId: prevState.selectedSpaceId,
      selectedSpaceId: null,
    }))
  }

  getLastSelectedSpaceId() {
    return this.state.lastSelectedSpaceId
  }

  getSelectedSpaceId() {
    return this.state.selectedSpaceId
  }
}

export default new SelectedSpaceStore()
