import {ME} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {type Channel, ChannelRecord} from "~/records/ChannelRecord"
import type {Message} from "~/records/MessageRecord"
import type {SpaceReadyData} from "~/records/SpaceRecord"
import SelectedChannelStore from "~/stores/SelectedChannelStore"
import * as RouterUtils from "~/utils/RouterUtils"

type State = {
  channels: Record<string, ChannelRecord>
}

const initialState: State = {
  channels: {},
}

class ChannelStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "SPACE_CREATE":
        return this.handleSpaceCreate(action.space)
      case "SPACE_DELETE":
        return this.handleSpaceDelete(action)
      case "CHANNEL_CREATE":
      case "CHANNEL_UPDATE":
        return this.handleChannelCreate(action)
      case "CHANNEL_POSITIONS_UPDATE":
        return this.handleChannelPositionsUpdate(action)
      case "CHANNEL_PINS_UPDATE":
        return this.handleChannelPinsUpdate(action)
      case "CHANNEL_DELETE":
        return this.handleChannelDelete(action)
      case "MESSAGE_CREATE":
        return this.handleMessageCreate(action)
      default:
        return false
    }
  }

  getChannel(channelId: string): ChannelRecord | undefined {
    return this.state.channels[channelId]
  }

  getSpaceChannels(spaceId: string): Array<ChannelRecord> {
    return Object.values(this.state.channels)
      .filter((channel) => channel.spaceId === spaceId)
      .sort((a, b) => a.position - b.position)
  }

  useSpaceChannels(spaceId: string): Array<ChannelRecord> {
    const {channels} = this.useStore()
    return Object.values(channels)
      .filter((channel) => channel.spaceId === spaceId)
      .sort((a, b) => a.position - b.position)
  }

  useChannel(channelId: string): ChannelRecord | null {
    const {channels} = this.useStore()
    return channels[channelId] ?? null
  }

  private handleConnectionOpen({spaces}: {spaces: Array<SpaceReadyData>}) {
    this.setState({channels: {}})
    for (const space of spaces) {
      this.handleSpaceCreate(space)
    }
  }

  private handleSpaceCreate(space: SpaceReadyData) {
    if (space.unavailable) {
      return
    }

    this.setState((prevState) => {
      const newChannels = {...prevState.channels}
      for (const channel of space.channels) {
        newChannels[channel.id] = new ChannelRecord(channel)
      }
      return {channels: newChannels}
    })
  }

  private handleSpaceDelete({spaceId}: {spaceId: string}) {
    this.setState((prevState) => {
      const newChannels = {...prevState.channels}
      for (const channelId in newChannels) {
        if (newChannels[channelId].spaceId === spaceId) {
          delete newChannels[channelId]
        }
      }
      return {channels: newChannels}
    })
  }

  private handleChannelCreate({channel}: {channel: Channel}) {
    this.setState((prevState) => {
      const newChannels = {...prevState.channels}
      newChannels[channel.id] = new ChannelRecord(channel)
      return {channels: newChannels}
    })
  }

  private handleChannelPositionsUpdate({spaceId, positions}: {spaceId: string; positions: Array<string>}) {
    this.setState((prevState) => {
      const newChannels = {...prevState.channels}
      for (const [index, channelId] of positions.entries()) {
        const channel = newChannels[channelId]
        if (channel && channel.spaceId === spaceId) {
          newChannels[channelId] = new ChannelRecord({
            ...channel.toJSON(),
            position: index,
          })
        }
      }
      return {channels: newChannels}
    })
  }

  private handleChannelPinsUpdate({channelId, lastPinAt}: {channelId: string; lastPinAt: number}) {
    this.setState((prevState) => {
      const newChannels = {...prevState.channels}
      const channel = newChannels[channelId]
      if (!channel) {
        return prevState
      }

      const updatedChannel = new ChannelRecord({
        ...channel.toJSON(),
        last_pin_at: lastPinAt,
      })

      newChannels[channelId] = updatedChannel
      return {channels: newChannels}
    })
  }

  private handleChannelDelete({channel}: {channel: Channel}) {
    this.setState((prevState) => {
      const newChannels = {...prevState.channels}
      delete newChannels[channel.id]
      return {channels: newChannels}
    })
    if (SelectedChannelStore.getSelectedChannel(channel.space_id ?? ME) === channel.id) {
      RouterUtils.transitionTo(`/channels/${channel.space_id ?? ME}`)
    }
  }

  private handleMessageCreate({message}: {message: Message}) {
    this.setState((prevState) => {
      const newChannels = {...prevState.channels}
      const channel = newChannels[message.channel_id]
      if (!channel) {
        return prevState
      }

      const updatedChannel = new ChannelRecord({
        ...channel.toJSON(),
        last_message_id: message.id,
      })

      newChannels[message.channel_id] = updatedChannel
      return {channels: newChannels}
    })
  }
}

export default new ChannelStore()
