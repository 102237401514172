import type {Action} from "~/flux/ActionTypes"
import {type StateUpdater, Store} from "~/flux/Store"

type PersistedState<T> = {
  state: Partial<T>
  version: number
}

export abstract class PersistedStore<T extends object> extends Store<T> {
  private storageKey: string
  private version: number
  private migrate?: (state: Partial<T>, oldVersion: number, newVersion: number) => T
  private keysToPersist: Array<keyof T>

  constructor(
    initialState: T,
    storageKey: string,
    version: number,
    keysToPersist?: Array<keyof T>,
    migrate?: (state: Partial<T>, oldVersion: number, newVersion: number) => T,
  ) {
    super(initialState)
    this.storageKey = storageKey
    this.version = version
    this.migrate = migrate
    this.keysToPersist = keysToPersist ?? (Object.keys(initialState) as Array<keyof T>)

    const savedState = this.loadState()
    if (savedState) {
      this.state = savedState
    }
  }

  /**
   * Load the state from localStorage.
   * @returns The loaded state or null if loading failed.
   */
  private loadState(): T | null {
    try {
      const serializedState = localStorage.getItem(this.storageKey)
      if (!serializedState) {
        return null
      }

      const persistedState: PersistedState<T> = JSON.parse(serializedState)

      if (persistedState.version !== this.version) {
        if (this.migrate) {
          return this.migrate(persistedState.state, persistedState.version, this.version)
        }
        this.saveState(this.state)
        return null
      }

      const partialState = persistedState.state
      const newState: T = {...this.state}

      for (const key of this.keysToPersist) {
        if (partialState[key] !== undefined) {
          newState[key] = partialState[key] as T[keyof T]
        }
      }

      return newState
    } catch (error) {
      console.error("Failed to load state:", error)
      return null
    }
  }

  /**
   * Save the state to localStorage.
   * @param state The state to save.
   */
  private saveState(state: T): void {
    try {
      const partialState: Partial<T> = {}
      for (const key of this.keysToPersist) {
        partialState[key] = state[key]
      }

      const persistedState: PersistedState<T> = {
        state: partialState,
        version: this.version,
      }
      localStorage.setItem(this.storageKey, JSON.stringify(persistedState))
    } catch (error) {
      console.error("Failed to save state:", error)
    }
  }

  /**
   * Override setState to also save the state to localStorage.
   * @param newState The new state or a function that returns the new state
   * based on the previous state.
   */
  protected override setState(newState: T | StateUpdater<T>): void {
    super.setState(newState)
    this.saveState(this.state)
  }

  abstract override handleAction(action: Action): void
}
