import {ChannelTypes} from "~/Constants"
import type {Action} from "~/flux/ActionTypes"
import {PersistedStore} from "~/flux/PersistedStore"
import ChannelStore from "~/stores/ChannelStore"

type State = {
  selectedChannelIds: Record<string, string>
  selectedChannelId: string | null
}

const initialState: State = {
  selectedChannelIds: {},
  selectedChannelId: null,
}

class SelectedChannelStore extends PersistedStore<State> {
  constructor() {
    super(initialState, "SelectedChannelStore", 1)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CHANNEL_SELECT":
        return this.handleChannelSelect(action)
      case "CHANNEL_DESELECT":
        return this.handleChannelDeselect()
      default:
        return false
    }
  }

  getSelectedChannel(spaceId: string): string {
    return this.state.selectedChannelIds[spaceId] ?? ""
  }

  useSelectedChannel(spaceId: string): string {
    const {selectedChannelIds} = this.useStore()
    const selectedChannelId = selectedChannelIds[spaceId]
    if (!selectedChannelId) {
      const channels = ChannelStore.getSpaceChannels(spaceId)
      const firstTextChannel = channels.find((channel) => channel.type === ChannelTypes.SPACE_TEXT)
      if (firstTextChannel) {
        return firstTextChannel.id
      }
      return ""
    }
    return selectedChannelId
  }

  private handleChannelSelect({spaceId, channelId}: {spaceId: string; channelId: string}) {
    this.setState((prevState) => {
      const selectedChannelIds = {...prevState.selectedChannelIds}
      selectedChannelIds[spaceId] = channelId
      return {
        selectedChannelIds,
        selectedChannelId: channelId,
      }
    })
  }

  private handleChannelDeselect() {
    this.setState((prevState) => ({
      ...prevState,
      selectedChannelId: null,
    }))
  }
}

export default new SelectedChannelStore()
