import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import {type Space, type SpaceReadyData, SpaceRecord} from "~/records/SpaceRecord"
import {type SpaceRole, SpaceRoleRecord} from "~/records/SpaceRoleRecord"
import SelectedSpaceStore from "~/stores/SelectedSpaceStore"
import * as RouterUtils from "~/utils/RouterUtils"

type State = {
  spaces: Record<string, SpaceRecord>
}

const initialState: State = {
  spaces: {},
}

class SpaceStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "SPACE_CREATE":
        return this.handleSpaceCreate(action.space)
      case "SPACE_UPDATE":
        return this.handleSpaceUpdate(action.space)
      case "SPACE_DELETE":
        return this.handleSpaceDelete(action)
      case "SPACE_ROLE_CREATE":
        return this.handleSpaceRoleCreate(action)
      case "SPACE_ROLE_DELETE":
        return this.handleSpaceRoleDelete(action)
      case "SPACE_ROLE_UPDATE":
        return this.handleSpaceRoleUpdate(action)
      default:
        return false
    }
  }

  getSpace(spaceId: string): SpaceRecord | undefined {
    return this.state.spaces[spaceId]
  }

  getSpaceRoles(spaceId: string, includeEveryone = false): Array<SpaceRoleRecord> {
    const space = this.state.spaces[spaceId]
    if (!space) {
      return []
    }
    return Object.values(space.roles).filter((role) => includeEveryone || role.id !== spaceId)
  }

  getSpaces(): Array<SpaceRecord> {
    return Object.values(this.state.spaces)
  }

  useSpace(spaceId: string): SpaceRecord | undefined {
    const {spaces} = this.useStore()
    return spaces[spaceId]
  }

  private handleConnectionOpen({spaces}: {spaces: Array<SpaceReadyData>}): void {
    this.setState(initialState)
    for (const space of spaces) {
      if (!space.unavailable) {
        this.handleSpaceCreate(space)
      }
    }
  }

  private handleSpaceCreate(space: SpaceReadyData): void {
    if (space.unavailable) {
      return
    }
    this.setState((prevState) => {
      const updatedSpaces = {...prevState.spaces}
      updatedSpaces[space.id] = SpaceRecord.fromSpaceReadyData(space)
      return {spaces: updatedSpaces}
    })
  }

  private handleSpaceUpdate(space: Space): void {
    this.setState((prevState) => {
      const updatedSpaces = {...prevState.spaces}
      const spaceRecord = updatedSpaces[space.id]
      if (!spaceRecord) {
        return prevState
      }
      const updatedSpace = new SpaceRecord(space)
      updatedSpace.roles = spaceRecord.roles
      updatedSpaces[space.id] = updatedSpace
      return {spaces: updatedSpaces}
    })
  }

  private handleSpaceDelete({spaceId, unavailable}: {spaceId: string; unavailable?: boolean}): void {
    this.setState((prevState) => {
      const updatedSpaces = {...prevState.spaces}
      delete updatedSpaces[spaceId]
      return {spaces: updatedSpaces}
    })
    if (!unavailable && SelectedSpaceStore.getSelectedSpaceId() === spaceId) {
      RouterUtils.transitionTo("/")
    }
  }

  private handleSpaceRoleCreate({spaceId, role}: {spaceId: string; role: SpaceRole}): void {
    this.setState((prevState) => {
      const updatedSpaces = {...prevState.spaces}
      const space = updatedSpaces[spaceId]
      if (!space) {
        return prevState
      }
      const updatedRoles = {...space.roles}
      updatedRoles[role.id] = new SpaceRoleRecord(spaceId, role)
      const updatedSpace = new SpaceRecord({...space.toJSON()})
      updatedSpace.roles = updatedRoles
      updatedSpaces[spaceId] = updatedSpace
      return {spaces: updatedSpaces}
    })
  }

  private handleSpaceRoleDelete({spaceId, roleId}: {spaceId: string; roleId: string}): void {
    this.setState((prevState) => {
      const updatedSpaces = {...prevState.spaces}
      const space = updatedSpaces[spaceId]
      if (!space) {
        return prevState
      }
      const updatedRoles = {...space.roles}
      delete updatedRoles[roleId]
      const updatedSpace = new SpaceRecord({...space.toJSON()})
      updatedSpace.roles = updatedRoles
      updatedSpaces[spaceId] = updatedSpace
      return {spaces: updatedSpaces}
    })
  }

  private handleSpaceRoleUpdate({spaceId, role}: {spaceId: string; role: SpaceRole}): void {
    this.setState((prevState) => {
      const updatedSpaces = {...prevState.spaces}
      const space = updatedSpaces[spaceId]
      if (!space) {
        return prevState
      }
      const updatedRoles = {...space.roles}
      updatedRoles[role.id] = new SpaceRoleRecord(spaceId, role)
      const updatedSpace = new SpaceRecord({...space.toJSON()})
      updatedSpace.roles = updatedRoles
      updatedSpaces[spaceId] = updatedSpace
      return {spaces: updatedSpaces}
    })
  }
}

export default new SpaceStore()
