import type {Channel} from "~/records/ChannelRecord"
import type {SpaceEmoji} from "~/records/SpaceEmojiRecord"
import type {SpaceMember} from "~/records/SpaceMemberRecord"
import type {SpaceRole} from "~/records/SpaceRoleRecord"
import {SpaceRoleRecord} from "~/records/SpaceRoleRecord"
import type {Presence} from "~/stores/PresenceStore"
import * as SnowflakeUtils from "~/utils/SnowflakeUtils"

export type Space = {
  id: string
  name: string
  description: string | null
  icon: string | null
  vanity_url_code: string | null
  owner_id: string
  system_channel_id: string | null
  unavailable?: boolean
}

export type SpaceReadyData = {
  id: string
  properties: Omit<Space, "roles">
  channels: Array<Channel>
  emojis: Array<SpaceEmoji>
  members: Array<SpaceMember>
  presences: Array<Presence>
  roles: Array<SpaceRole>
  unavailable?: boolean
}

export class SpaceRecord {
  id: string
  name: string
  description: string | null
  icon: string | null
  vanityURLCode: string | null
  ownerId: string
  systemChannelId: string | null
  roles: Record<string, SpaceRoleRecord>
  unavailable?: boolean

  constructor(space: Space) {
    this.id = space.id
    this.name = space.name
    this.description = space.description
    this.icon = space.icon
    this.vanityURLCode = space.vanity_url_code
    this.ownerId = space.owner_id
    this.systemChannelId = space.system_channel_id
    this.roles = {}
    this.unavailable = space.unavailable
  }

  static fromSpaceReadyData(spaceData: SpaceReadyData): SpaceRecord {
    const space = new SpaceRecord(spaceData.properties)
    space.roles = spaceData.roles.reduce(
      (roles, role) => {
        roles[role.id] = new SpaceRoleRecord(space.id, role)
        return roles
      },
      {} as Record<string, SpaceRoleRecord>,
    )
    space.unavailable = spaceData.unavailable
    return space
  }

  toJSON(): Space {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      icon: this.icon,
      vanity_url_code: this.vanityURLCode,
      owner_id: this.ownerId,
      system_channel_id: this.systemChannelId,
      unavailable: this.unavailable,
    }
  }

  update(space: Space): void {
    this.name = space.name
    this.description = space.description
    this.icon = space.icon
    this.vanityURLCode = space.vanity_url_code
    this.ownerId = space.owner_id
    this.systemChannelId = space.system_channel_id
    this.unavailable = space.unavailable
  }

  get createdAt(): number {
    return SnowflakeUtils.extractTimestamp(this.id)
  }

  isOwner(userId: string): boolean {
    return this.ownerId === userId
  }
}
